import { FieldComponentTypes } from 'shared/src/utils/shared.js';

const readyFormSubmit = `var readyFormSubmit = function(entityId, isPreview){
    checkAndLoadDeps();

    addSubmitEventToButton(isPreview);

    addNextEventToButton(isPreview);

    addBackEventToButton(isPreview);

    addGoToPageEventToButton();

    checkboxOnClickEvents();

    prefillFormFields();
    
    prepareFormSelects();

    prepareFormPhoneInputs();

    prepareFormMultiSelects();

    restrictNumberFields();

    renderRecaptcha();

    prepareFormFileUploadInputs();
            
    onInputCallbacks();

    preparePopupMessageClose();

    if (isPreview){
        window.addEventListener('message', function(e) {
            messageListener(e, form);
        });
    }

    triggerView();
}`;

const messageListener = `var messageListener = function(event, form){
    if (event.origin !== location.origin) return;

    if (event.data.event === "webhookTest") {
        if (event.data.message === 'pass'){
            triggerSuccessAction();
        } else if (event.data.message === 'fail'){
            triggerFailAction();
        }
    }
}`;

const addSubmitEventToButton = `var addSubmitEventToButton = function(isPreview){
    var formButton = summaryPage
        ? form.querySelectorAll('.summary-page .submit-button')
        : form.querySelectorAll('.submit-button');
    
    var formId = form.getAttribute('data-formid');
    var callback;

    if (formButton && formButton.length){
        formButton[0].onclick = buttonCallback.bind(form, formButton[0].onclick, callback, isPreview);
    }
}`;

const addNextEventToButton = `var addNextEventToButton = function(isPreview){
    var formButtons = summaryPage 
        ? form.querySelectorAll('.next-button, .submit-button:not(.summary-page .submit-button)') 
        : form.querySelectorAll('.next-button');

    var onNextClick = function(e){
        e.preventDefault();
        triggerInteraction();

        var page = form.querySelector('[data-page-index="' + currentPage + '"]');
        var inputs = Array.from(page.getElementsByTagName('input')).concat(Array.from(page.getElementsByTagName('textarea')));

        var validInputs = validateFormInputs(inputs, false);
        if (!validInputs){
            return false;
        }

        currentPage += 1;

        page.className = page.className + ' fade-out';
        setTimeout(() => {
            page.style.display = 'none';
            page.style.opacity = 0;
            page.className = page.className.replace(' fade-out', '');
            
            showNewPage(page, 'next');

        }, 150);
    }

    for (var i = 0; i < formButtons.length; i++){
        formButtons[i].onclick = onNextClick;
    }
}`;

const addBackEventToButton = `var addBackEventToButton = function(isPreview){
    var formButtons = form.querySelectorAll('.back-button');

    var onBackClick = function(e){
        e.preventDefault();
        var page = form.querySelector('[data-page-index="' + currentPage + '"]');
        
        currentPage -= 1;

        page.className = page.className + ' fade-out';
        setTimeout(() => {
            page.style.display = 'none';
            page.style.opacity = 0;
            page.className = page.className.replace(' fade-out', '');
            
            showNewPage(page, 'prev');

        }, 150);
    }

    for (var i = 0; i < formButtons.length; i++){
        formButtons[i].onclick = onBackClick;
    }
}`;

const addGoToPageEventToButton = `var addGoToPageEventToButton = function(){
    var goToPageButtons = form.querySelectorAll('.summary-page-edit-page');

    var onClick = function(e){
        e.stopPropagation();
        var page = form.querySelector('[data-page-index="' + currentPage + '"]');
        
        currentPage = parseInt(e.target.dataset.targetPage, 10);

        page.className = page.className + ' fade-out';
        setTimeout(() => {
            page.style.display = 'none';
            page.style.opacity = 0;
            page.className = page.className.replace(' fade-out', '');
            
            showNewPage(page, 'prev');
        }, 150);
    }

    for (var i = 0; i < goToPageButtons.length; i++){
        goToPageButtons[i].onclick = onClick;
    }
}`;

const addSummaryPageCollapseEventToButton = `var addSummaryPageCollapseEventToButton = function(){
    var collapseButtons = form.querySelectorAll('.summary-page-collapse-button');

    var onClick = function(e){
        const wrapper = e.target.closest('.page-wrapper')
        const toggled = wrapper.classList.toggle('collapsed');
        wrapper.querySelector('.summary-page-collapse-button button').setAttribute("aria-expanded", !toggled);
    };

    for (var i = 0; i < collapseButtons.length; i++){
        var button = collapseButtons[i];
        var collapseWrapper = button.nextElementSibling;
        collapseWrapper.style.height = '';
        collapseWrapper.style.height = collapseWrapper.clientHeight + 'px';
        collapseButtons[i].onclick = onClick;
    }
}`;

const setSummaryPageFieldValues = `var setSummaryPageFieldValues = function(){
    var inputs = Array.from(form.getElementsByTagName('input')).concat(Array.from(form.getElementsByTagName('textarea')));
    var fieldValues = inputs.reduce((acc, field) => {
        const { value, checked, dataset: { type, id, isCheckboxGroup }} = field;

        if (isCheckboxGroup || !id || !type) return acc;
        
        let fieldValue = value || '-';

        if (type === 'checkbox') {
            fieldValue = checked ? 'Yes' : 'No';
        }
        if (type === 'checkbox_group') {
            fieldValue =  value ? JSON.parse(value).join(', ') : '-';
        }
        if (type === 'file_upload' && typeof value === 'string' ) {
            const fileUploadWrapper = form.querySelector('[id="' + id + '"]')
            fieldValue = value 
                ? fileUploadWrapper.querySelector('.file_upload-files').cloneNode(true)
                : '-';
        }
        return ({...acc, [id]: fieldValue})}, 
    {});    
    
    var summaryPageFields = summaryPage.querySelectorAll('.summary-page-field-value');
    summaryPageFields.forEach((field) => {
        const { id, type } = field.dataset;
        const value = fieldValues[id];
        field.textContent = fieldValues[id];

        if (type === 'file_upload' && typeof value !== 'string') {
            field.innerHTML = '';
            field.appendChild(fieldValues[id]);
        }
    });
}`;

const initSummaryPage = `var initSummaryPage = function(){
    setSummaryPageFieldValues();
    addSummaryPageCollapseEventToButton();
}`;

const showNewPage = `var showNewPage = function(page, type){
    var newPage = form.querySelector('[data-page-index="' + currentPage + '"]');
    if (!newPage){
        currentPage = type === 'next' ? currentPage + 1 : currentPage - 1;
        return showNewPage(page, type);
    }
    newPage.style.display = 'flex';

    newPage.style.opacity = 1;

    newPage.className = newPage.className + ' fade-in';

    if (newPage.className.includes('summary-page')) {
        initSummaryPage();
    }

    setTimeout(() => {
        newPage.className = newPage.className.replace(' fade-in', '');
    }, 200)
}`;

const buttonCallback = `var buttonCallback = function(onClick, callback, isPreview, e){
    e.preventDefault();
    triggerInteraction();
    var formData = getFormData();

    if (typeof recaptchaInstance !== "undefined" && !grecaptcha.getResponse(recaptchaInstance)){
        var recaptcha = this.querySelector('#recaptcha' + this.getAttribute('data-formid'));

        var message = document.createElement('div');
        message.innerText = validationMessages.recaptcha || 'Validate using the reCAPTCHA';
        message.className = 'form-recaptcha-error-message ' + recaptcha.className.replace('g-recaptcha', '');
        recaptcha.parentNode.insertBefore(message, recaptcha.nextSibling);

        return;
    }
    
    if (formData){
        if(isPreview){
            window.parent.postMessage({formData: formData, event: 'previewData'});
        }else{
            var submitTo = '###__SUBMIT_URL__###';
            postData(submitTo, formData, callback);
        }
    }
    if (onClick){
        onClick(e);
    }
}`;

const getFormData = `var getFormData = function(){
    var valuesObj = {}
    var valuesArray = [];

    var inputs = Array.from(form.getElementsByTagName('input')).concat(Array.from(form.getElementsByTagName('textarea')));

    if (inputs && inputs.length > 0){
        var validInputs = validateFormInputs(inputs, true);
        if (!validInputs){
            return false;
        }
        var inputValues = getCollectionValues(inputs);
        valuesArray = valuesArray.concat(inputValues);
    }

    valuesObj = correctObjectStructure(valuesArray);
    return valuesObj;
}`;

const createErrorMessageElement = `var createErrorMessageElement = function(message){
	const el = document.createElement('div');

	el.innerHTML = '<span class="mdi mdi-alert-circle" aria-hidden="true"></span> ' + message;
	el.title = message;
	el.className = 'global-input-error-message form-input-error-message';

	return el;
}`;

const validateFormInputs = `var validateFormInputs = function(collection, isLastPage){
    clearAllErrorMessages();

    var isRowForm = form.className.includes('lp-flex-direction-horizontal') ? true : false;
    var isValid = true;
    var errorMessage = '';
    var errorsLiveRegion = form.querySelector('[id="errors-live-region"]');

    for (var i = 0; i < collection.length; i++){

        var isInvalidField = false;
        var required = collection[i].getAttribute('data-required');

        if (collection[i].className === 'checkbox-group-input'){
            const checkboxes = collection[i].parentNode.getElementsByTagName('input');
            const checkboxValues = [];
            for (var j = 0; j < checkboxes.length; j++){
                if (checkboxes[j].checked){
                    checkboxValues.push(checkboxes[j].value);
                }
            }
            if (checkboxValues.length){
                collection[i].value = JSON.stringify(checkboxValues);
            } else {
                collection[i].value = '';
            }
        } else if (collection[i].className === 'radio-group-input'){
            const radios = collection[i].parentNode.getElementsByTagName('input');
            const radioValue = '';
            for (var j = 0; j < radios.length; j++){
                if (radios[j].checked){
                    collection[i].value = radios[j].value;
                    break;
                }
            }
        }

        var name = collection[i].getAttribute('data-label');
        var value = collection[i].type === 'checkbox' ? collection[i].checked : collection[i].value;

        if (required === 'true' && !value){
            errorMessage = validationMessages.required;
            isInvalidField = true;
            isValid = false;
        } else if (collection[i].type === 'email') {
            const greekCharacters = /[Α-Ωα-ω]/;
            if (greekCharacters.test(collection[i].value)){
                errorMessage = 'The characters you entered are not supported.';
                isInvalidField = true;
                isValid = false;
            } else if (!collection[i].checkValidity()){
                errorMessage = validationMessages.email;
                isInvalidField = true;
                isValid = false;
            }
        } else if (!collection[i].checkValidity()){
            errorMessage = collection[i].validity.valueMissing && required === 'true' ? validationMessages.required : collection[i].validationMessage;
            isInvalidField = true;
            isValid = false;
        } else if (collection[i].getAttribute('data-type') === 'phone_input'){
            if (required === 'false' && !collection[i].value) {
                isInvalidField = false;
            } else {
                const phoneInputInstance = window[collection[i].id];
                collection[i].value = phoneInputInstance.getNumber();
                collection[i].value.trim();

                if (phoneInputInstance && !phoneInputInstance.isValidNumber()){
                    isValid = false;
                    isInvalidField = true;

                    errorMessage = validationMessages.phone;
                }
            }
        }

        if (isInvalidField){
            var parentNode = form.querySelector('[id="'+collection[i].getAttribute('data-id')+'"]');

            if (parentNode) {
                if (collection[i].className === 'checkbox-group-input') {
                    collection[i].closest('[role="group"]').setAttribute('aria-invalid', 'true');
                } else if (collection[i].className === 'radio-group-input') {
                    collection[i].closest('[role="radiogroup"]').setAttribute('aria-invalid', 'true');
                } else if (collection[i].getAttribute('data-type') === 'file_upload') {
                    collection[i].previousSibling.setAttribute('aria-invalid', 'true');
                } else {
                    collection[i].setAttribute('aria-invalid', 'true');
                }

                if (!errorsLiveRegion.innerHTML) {
                    errorsLiveRegion.innerHTML = 'The following errors occured:<br />' + name + ': ' + errorMessage + ',<br />';
                } else {
                    errorsLiveRegion.innerHTML = errorsLiveRegion.innerHTML + name + ': ' + errorMessage + ',<br />';
                }

                var errorContainerNode = parentNode.querySelector('.component-error-container');
                var message = createErrorMessageElement(errorMessage);

                if (errorContainerNode) {
                  errorContainerNode.style.display = "block";
                  errorContainerNode.appendChild(message);
                } else {
                  parentNode.className = parentNode.className + ' form-input-error-field';

                  var computedStyle = window.getComputedStyle(parentNode);
                  var paddingLeft = computedStyle.getPropertyValue('padding-left');
                  var paddingRight = computedStyle.getPropertyValue('padding-right');
  
                  message.style.width = 'calc(100% - ' + paddingLeft + ' - ' + paddingRight + ')';
  
                  parentNode.appendChild(message);
                }
            }
        }
    }

    if (isLastPage && typeof recaptchaInstance !== "undefined" && !grecaptcha.getResponse(recaptchaInstance)) {
        var errMsg = validationMessages.recaptcha || 'Validate using the reCAPTCHA';

        if (!errorsLiveRegion.innerHTML) {
            errorsLiveRegion.innerHTML = 'The following errors occured:<br />' + 'reCAPTCHA' + ': ' + errMsg + ',<br />';
        } else {
            errorsLiveRegion.innerHTML = errorsLiveRegion.innerHTML + 'reCAPTCHA' + ': ' + errMsg + ',<br />';
        }
    }

    return isValid;
}`;

const getCollectionValues = `var getCollectionValues = function(collection){
    var valuesArray = [];
    for (var i = 0; i < collection.length; i++){
        var value;
        let skip = false;
        if (collection[i].type === 'hidden'){
            value = collection[i].checked ? collection[i].checked : collection[i].value;
        } else if (collection[i].type === 'file') {
          skip = true;
        } else {
            value = collection[i].type === 'checkbox' ? collection[i].checked : collection[i].value;
        }
        var name = collection[i].name;
        if (!skip)
          valuesArray.push({name: name, value: transformInputValue(collection[i], value)});
    }
    return valuesArray;
}`;

const transformInputValue = `var transformInputValue = function(input, value){
    var valuesArray = [];

    switch(input.getAttribute('data-type')){
        case 'checkbox_group':
        case 'file_upload':
            return value ? JSON.parse(value) : [];
            break;
        case 'singleselect_input':
            return input.getAttribute('data-value') ?? '';
            break;
        case 'multiselect_input':
            return JSON.parse(input.getAttribute('data-values'));
            break;

        default:
            return value;
    }
}`;

const correctObjectStructure = `var correctObjectStructure = function(collection){
	var valuesObj = {};

	for (var i = 0; i < collection.length; i++) {
		var field = collection[i];

		if (field.name) {
			valuesObj[field.name] = field.value;
		}
	}

	return valuesObj;
}`;

const postData = `var postData = function (url, data, callback) {
    var button = form.querySelector('[data-component-type=submit_button] .submit-button');

    button.disabled = true;
    button.style.cursor = 'not-allowed';

    var loaderEl = document.createElement('span');
    loaderEl.className = 'mdi mdi-loading button-loader';
    var loaderElRef = button.appendChild(loaderEl);

    return fetch(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body:  JSON.stringify({Data: JSON.stringify(data)})
    })
    .then(function(response){

        button.disabled = false;
        button.style.cursor = 'pointer';

        loaderElRef.parentNode.removeChild(loaderElRef);

        if (response.ok){
            response.json().then(function(rsp) {
                if(rsp){
                    triggerSubmit();
                    triggerSuccessAction();
                }else{
                    triggerFailAction();
                } 
            })    
        } else {
            triggerFailAction();
        }
    })
    .catch(function(e){
        button.disabled = false;
        button.style.cursor = 'pointer';
        button.removeChild(button.children[0]);
        console.error(e);
    });
}`;

const checkboxOnClickEvents = `var checkboxOnClickEvents = function(){
    var checkboxes = form.querySelectorAll(".form-input-checkbox-element");
    for (var i = 0; i < checkboxes.length; i++){
        var readOnly = checkboxes[i].getAttribute('data-readOnly');
        if (readOnly !== 'true'){
            checkboxes[i].onclick = function(currentGroup) {
                var isCheckboxGroup = this.getAttribute('data-is-checkbox-group');

                if (isCheckboxGroup){
                    var group = this.closest('.checkbox-group-container');
                    var groupLimit = group.getAttribute('data-options-limit') ? parseInt(group.getAttribute('data-options-limit'), 10) : 0;
                    var groupSelectedCount = group.getAttribute('data-selected-count') ? parseInt(group.getAttribute('data-selected-count'), 10) : 0;
                    if (this.checked){
                        if (!groupLimit || (groupLimit && groupSelectedCount + 1 <= groupLimit)){
                            this.nextSibling.style.display = 'block';
                            groupSelectedCount += 1;
                            group.setAttribute('data-selected-count', groupSelectedCount);
                            this.checked = true;
                        } else {
                            this.checked = false;
                        }
                    } else {
                        this.nextSibling.style.display = 'none';
                        if (groupSelectedCount){
                            groupSelectedCount -= 1;
                            group.setAttribute('data-selected-count', groupSelectedCount);
                        }
                    }
                } else {
                    if (this.checked){
                        this.nextSibling.style.display = 'block';
                    } else {
                        this.nextSibling.style.display = 'none';
                    }
                }
            };
        }
    }
}`;

const prepareFormSelects = `var prepareFormSelects = function() {
	var selectElements = form.querySelectorAll(".form-input-select-element:not(.form-input-multiselect-element)");

	for (var i = 0; i < selectElements.length; i++) {
		var optionsContainer = selectElements[i] && selectElements[i].querySelectorAll(".form-select-option-container")[0];

		if (optionsContainer) {
			var input = selectElements[i].getElementsByTagName("input")[0];
			const options = optionsContainer.querySelectorAll('.form-select-option');

			var highlightOption = function(inp, option, highlightedOption) {
				if (highlightedOption) {
					highlightedOption.classList.remove('highlighted');
				}

				option.classList.add('highlighted');
				option.scrollIntoView({
					block: 'nearest',
				});

				inp.setAttribute('aria-activedescendant', option.id);
			};

			var toggleDisplay = function(container) {
				if (container.style.display === "none") {
					var highlightedOption = container.querySelector('.form-select-option.highlighted');

					if (highlightedOption) {
						highlightedOption.classList.remove('highlighted');
					}

					var selectedOption = container.querySelector('.form-select-option[aria-selected="true"]');

					container.style.display = "block";
					input.setAttribute('aria-expanded', 'true');

					if (selectedOption) {
						highlightOption(input, selectedOption);
					}
				} else {
					container.style.display = "none";
					input.setAttribute('aria-expanded', 'false');
					input.setAttribute('aria-activedescendant', '');
				}
			};

			var createInputCb = function(index) {
				var inp = selectElements[index].getElementsByTagName("input")[0];

				return function(option) {
                    var parentNode = form.querySelector('[id="'+input.getAttribute('data-id')+'"]');

                    if (parentNode) {
                        parentNode.className = parentNode.className.replace(' form-input-error-field', '') + '';
                        var errorElement = parentNode.querySelectorAll('.form-input-error-message');
                        if (errorElement && errorElement.length){
                            parentNode.removeChild(errorElement[0]);
                        }
                    }

					var selectedIndicator = option.querySelector('.selected-indicator');
					var textIndicator = option.querySelector('.text-indicator');

					if (!selectedIndicator.classList.contains('mdi-check')) {
						options.forEach(function(opt) {
							var optionSelectedIndicator = opt.querySelector('.selected-indicator');

							optionSelectedIndicator.classList.remove('mdi-check');
							opt.setAttribute('aria-selected', 'false');
						});
					}

					selectedIndicator.classList.add('mdi-check');
					option.setAttribute('aria-selected', 'true');
					inp.value = option.textContent;
					inp.setAttribute('data-value', option.getAttribute('data-value'));
					runLogics(inp);
				};
			};

			const onEnter = createInputCb.bind(this, i)();

			var handleKeyDown = function(container, event) {
				var isContainerOpen = container.style.display === "block";

				switch (event.key) {
					case ' ':
						event.preventDefault();

						toggleDisplay(container);

						break;
					case 'Escape':
						event.preventDefault();

						if (isContainerOpen) {
							toggleDisplay(container);
						}

						break;
					case 'ArrowDown':
						event.preventDefault();

						var didContainerOpen = false;

						if (!isContainerOpen) {
							toggleDisplay(container);

							didContainerOpen = true;
						}

						var highlightedOption = container.querySelector('.form-select-option.highlighted'); 

						if (!highlightedOption) {
							var firstOption = container.querySelectorAll('.form-select-option')[0];

							if (firstOption) {
								highlightOption(input, firstOption);
							}
						} else {
							if (!didContainerOpen) {
								var nextOption = highlightedOption.nextElementSibling;

								if (nextOption) {
									highlightOption(input, nextOption, highlightedOption);
								}
							}
						}

						break;
					case 'ArrowUp':
						event.preventDefault();

						var didContainerOpen = false;

						if (!isContainerOpen) {
							toggleDisplay(container);

							didContainerOpen = true;
						}

						var highlightedOption = container.querySelector('.form-select-option.highlighted');

						if (!highlightedOption) {
							var opts = container.querySelectorAll('.form-select-option');
							var lastOption = opts[opts.length - 1];

							if (lastOption) {
								highlightOption(input, lastOption);
							}
						} else {
							if (!didContainerOpen) {
								var prevOption = highlightedOption.previousElementSibling;

								if (prevOption) {
									highlightOption(input, prevOption, highlightedOption);
								}
							}
						}

						break;
					case 'Enter':
						event.preventDefault();

						if (isContainerOpen) {
							var highlightedOption = container.querySelector('.form-select-option.highlighted');

							if (highlightedOption) {
								onEnter(highlightedOption);
								toggleDisplay(container);
							}
						}

						break;
				}
			};

			var readOnly = input.getAttribute('data-readOnly');

			if (readOnly !== 'true') {
				input.onclick = toggleDisplay.bind(this, optionsContainer);
				input.onkeydown = handleKeyDown.bind(this, optionsContainer);
				input.onblur = function(container) {
					setTimeout(function() {
						if (container.style.display === "block") {
							toggleDisplay(container);
						}
					}, 10);
				}.bind(this, optionsContainer);
			}

			for (var j = 0; j < options.length; j++) {
				var cb = createInputCb(i);

				options[j].onmousedown = cb.bind(this, options[j]);

				if (options[j].getAttribute('data-value') === input.getAttribute('data-value')) {
					options[j].setAttribute('aria-selected', 'true');
					options[j].querySelector('.selected-indicator').classList.add('mdi-check');
					input.value = options[j].textContent;
				}
			}
		}
	}
}`;

const prepareFormMultiSelects = `var prepareFormMultiSelects = function () {
	var selectElements = form.querySelectorAll(".form-input-multiselect-element");

	for (var i = 0; i < selectElements.length; i++) {
		var optionsContainer = selectElements[i] && selectElements[i].querySelectorAll('.form-select-option-container')[0];

		if (optionsContainer) {
			var input = selectElements[i].getElementsByTagName('input')[0];
			var options = optionsContainer.querySelectorAll('.form-select-option');
			var groupLimit = optionsContainer.getAttribute('data-options-limit') ? parseInt(optionsContainer.getAttribute('data-options-limit'), 10) : 0;

			var highlightOption = function(inp, option, highlightedOption) {
				if (highlightedOption) {
					highlightedOption.classList.remove('highlighted');
				}

				option.classList.add('highlighted');
				option.scrollIntoView({
					block: 'nearest',
				});

				inp.setAttribute('aria-activedescendant', option.id);
			};

			var toggleDisplay = function(container, shouldHighlightLastOption) {
				if (container.style.display === 'none') {
					var highlightedOption = container.querySelector('.form-select-option.highlighted');

					if (highlightedOption) {
						highlightedOption.classList.remove('highlighted');
					}

					var selectedOptions = container.querySelectorAll('.form-select-option[aria-selected="true"]');

					container.style.display = 'block';
					input.setAttribute('aria-expanded', 'true');

					if (shouldHighlightLastOption) {
						var lastSelectedOption = selectedOptions[selectedOptions.length - 1];

						if (lastSelectedOption) {
							highlightOption(input, lastSelectedOption);
						}
					} else {
						var firstSelectedOption = selectedOptions[0];

						if (firstSelectedOption) {
							highlightOption(input, firstSelectedOption);
						}
					}
				} else {
					container.style.display = 'none';
					input.setAttribute('aria-expanded', 'false');
					input.setAttribute('aria-activedescendant', '');
				}
			};

			var createInputCb = function(index, limit) {
				var inp = selectElements[index].getElementsByTagName('input')[0];

				return function(option) {

                    var parentNode = form.querySelector('[id="'+input.getAttribute('data-id')+'"]');

                    if (parentNode) {
                        parentNode.className = parentNode.className.replace(' form-input-error-field', '') + '';
                        var errorElement = parentNode.querySelectorAll('.form-input-error-message');
                        if (errorElement && errorElement.length){
                            parentNode.removeChild(errorElement[0]);
                        }
                    }

					var groupSelectedCount = inp.getAttribute('data-selected-count') ? parseInt(inp.getAttribute('data-selected-count'), 10) : 0;
					var selectedIndicator = option.querySelector('.selected-indicator');
					var textIndicator = option.querySelector('.text-indicator');

					if (selectedIndicator.classList.contains('mdi-check')) {
						selectedIndicator.classList.remove('mdi-check');
						option.setAttribute('aria-selected', 'false');

						if (groupSelectedCount) {
							groupSelectedCount -= 1;
							inp.setAttribute('data-selected-count', groupSelectedCount);
						}
					} else {
						if (limit && groupSelectedCount + 1 > limit) {
							return;
						}

						selectedIndicator.classList.add('mdi-check');
						option.setAttribute('aria-selected', 'true');
						groupSelectedCount += 1;
						inp.setAttribute('data-selected-count', groupSelectedCount);
					}

					var realValArr = JSON.parse(inp.getAttribute('data-values')) || [];
					var realLabelArr = JSON.parse(inp.getAttribute('data-labels')) || [];

					const ind = realValArr.indexOf(option.getAttribute('data-value'));

					if (ind > -1) {
						realValArr.splice(ind, 1); 
						realLabelArr.splice(ind, 1); 
					} else {
						realValArr.push(option.getAttribute('data-value'));
						realLabelArr.push(textIndicator.textContent);
					}

					inp.value = realLabelArr.join(', ');
					inp.setAttribute('data-values', JSON.stringify(realValArr));
					inp.setAttribute('data-labels', JSON.stringify(realLabelArr));
					runLogics(inp);
				};
			};

			var onEnter = createInputCb.bind(this, i, groupLimit)();

			var handleKeyDown = function(container, event) {
				var isContainerOpen = container.style.display === "block";

				switch (event.key) {
					case ' ':
						event.preventDefault();

						toggleDisplay(container);

						break;
					case 'Escape':
						event.preventDefault();

						if (isContainerOpen) {
							toggleDisplay(container);
						}

						break;
					case 'ArrowDown':
						event.preventDefault();

						var didContainerOpen = false;

						if (!isContainerOpen) {
							toggleDisplay(container);

							didContainerOpen = true;
						}

						var highlightedOption = container.querySelector('.form-select-option.highlighted'); 

						if (!highlightedOption) {
							var firstOption = container.querySelectorAll('.form-select-option')[0];

							if (firstOption) {
								highlightOption(input, firstOption);
							}
						} else {
							if (!didContainerOpen) {
								var nextOption = highlightedOption.nextElementSibling;

								if (nextOption) {
									highlightOption(input, nextOption, highlightedOption);
								}
							}
						}

						break;
					case 'ArrowUp':
						event.preventDefault();

						var didContainerOpen = false;

						if (!isContainerOpen) {
							toggleDisplay(container, true);

							didContainerOpen = true;
						}

						var highlightedOption = container.querySelector('.form-select-option.highlighted');

						if (!highlightedOption) {
							var opts = container.querySelectorAll('.form-select-option');
							var lastOption = opts[opts.length - 1];

							if (lastOption) {
								highlightOption(input, lastOption);
							}
						} else {
							if (!didContainerOpen) {
								var prevOption = highlightedOption.previousElementSibling;

								if (prevOption) {
									highlightOption(input, prevOption, highlightedOption);
								}
							}
						}

						break;
					case 'Enter':
						event.preventDefault();

						if (isContainerOpen) {
							var highlightedOption = container.querySelector('.form-select-option.highlighted');

							if (highlightedOption) {
								onEnter(highlightedOption);
							}
						}

						break;
				}
			};

			var readOnly = input.getAttribute('data-readOnly');

			if (readOnly !== 'true') {
				input.onclick = toggleDisplay.bind(this, optionsContainer);
				input.onkeydown = handleKeyDown.bind(this, optionsContainer);
				input.onblur = function(container) {
					setTimeout(function() {
						if (container.style.display === "block") {
							toggleDisplay(container);
						}
					}, 10);
				}.bind(this, optionsContainer);
			}

			for (var j = 0; j < options.length; j++) {
				var cb = createInputCb(i, groupLimit);
				var textIndicator = options[j].querySelector('.text-indicator');

				options[j].onmousedown = cb.bind(this, options[j]);

				if (JSON.parse(input.getAttribute('data-values')).includes(options[j].getAttribute('data-value'))) {
					options[j].setAttribute('aria-selected', 'true');		
					options[j].querySelector('.selected-indicator').classList.add('mdi-check');

					input.value = input.value ? input.value + ', ' + textIndicator.textContent : textIndicator.textContent;

					var realLabelArr = JSON.parse(input.getAttribute('data-labels')) || [];

					realLabelArr.push(textIndicator.textContent);
					input.setAttribute('data-labels', JSON.stringify(realLabelArr));
					input.setAttribute('data-selected-count', realLabelArr.length);
				}
			}
		}
	}
}`;

const prepareFormPhoneInputs = `var prepareFormPhoneInputs = function(){
	if (!window.intlTelInput) {
		setTimeout(function() {
			return prepareFormPhoneInputs();
		}, 500);

		return;
	}

	var inputs = form.querySelectorAll('input[data-type="phone_input"]');

	for (var i = 0; i < inputs.length; i++) {
		const input = inputs[i];
		const phoneInput = window.intlTelInput(input, {
			utilsScript: 'https://cdn.jsdelivr.net/npm/intl-tel-input@21.0.1/build/js/utils.js',
			initialCountry: 'us',
			nationalMode: true,
			countrySearch: true,
			showFlags: false,
			showSelectedDialCode: true,
			useFullscreenPopup: false,
		});

		window[input.id] = phoneInput;
	}
}`;

const promiseAllSettledSequencial = `var promiseAllSettledSequencial = function(promiseCallbacksArray){
	let finalPromise = Promise.resolve([]);

	promiseCallbacksArray.forEach((promise) => {
		finalPromise = finalPromise.then((responses) => {
			return promise()
				.then((response) => responses.concat([{ status: 'fulfilled', value: response }]))
				.catch((reason) => responses.concat([{ status: 'rejected', reason }]));
		});
	});

	return finalPromise;
}`;

const prepareFormFileUploadInputs = `var prepareFormFileUploadInputs = function(){
	const fileUploadContainers = Array.from(form.querySelectorAll('.component.file_upload-component'));
	if (!fileUploadContainers.length) return;

	fileUploadContainers.forEach((containerEl) => {
		const inputEl = containerEl.querySelector('.file_upload-input');
		const valueEl = inputEl.nextElementSibling;
		const errorContainerEl = containerEl.querySelector('.file_upload-error-container');
		const announceEl = containerEl.querySelector('.a11y-announce');
		const maxFiles = parseInt(inputEl.dataset.maxFiles);
		const fileSizeLimit = parseInt(inputEl.dataset.fileSizeLimit);
		const acceptedTypes = inputEl.accept.split(',');

		inputEl.addEventListener('dragenter', () => {
			if (!inputEl.disabled) inputEl.parentElement?.classList.add('has-drag');
		});

		inputEl.addEventListener('dragleave', () => inputEl.parentElement?.classList.remove('has-drag'));

		inputEl.addEventListener('change', (e) => {
			triggerInteraction();

			const inputFiles = e.target?.files || [];
			const value = JSON.parse(valueEl.value || '[]');
			inputEl.parentElement?.classList.remove('has-drag');

			if (!inputFiles.length) return;

			inputEl.removeAttribute('aria-invalid');
			errorContainerEl.innerHTML = '';
			errorContainerEl.style.display = 'none';

			let errors = [];
			let uploadedFilesCount = value.length;
			const uploadPromiseCbs = [];

			inputEl.disabled = true;
			inputEl.parentElement.classList.add('disabled');
			inputEl.title = 'Uploading files';
			announceEl.innerHTML = 'Uploading files...';
			announceEl.focus();

			for (const file of inputFiles) {
				const fileName = file.name;
				let error;

				if (!acceptedTypes.find((ext) => fileName.toLowerCase().endsWith(ext))) {
					error = file.name + ': ' + validationMessages.uploadFileType;
				} else if (file.size > fileSizeLimit) {
					error = file.name + ': ' + validationMessages.uploadFileSize;
				} else if (uploadedFilesCount === maxFiles) {
					error = validationMessages.uploadFileCount;
				}

				if (error) {
					errors = [...errors.filter((e) => e !== error), error];
				} else {
					uploadedFilesCount++;
					uploadPromiseCbs.push(uploadFile(file, containerEl, errorContainerEl, valueEl));
				}
			}

			inputEl.value = '';

			if (uploadPromiseCbs.length) {
				promiseAllSettledSequencial(uploadPromiseCbs).then(() => {
					inputEl.title = '';

					if (!errorContainerEl.innerHTML) {
						announceEl.innerHTML = 'Uploading complete';
					} else {
						announceEl.innerHTML = 'Uploading complete. Some errors occured: ' + errorContainerEl.innerHTML;
					}
					announceEl.focus();

					const newValue = JSON.parse(valueEl.value || '[]');
					if (newValue.length !== maxFiles) {
						inputEl.disabled = false;
						inputEl.parentElement.classList.remove('disabled');
					}
				});
			} else {
				inputEl.disabled = false;
				inputEl.parentElement.classList.remove('disabled');
			}

			errorContainerEl.style.display = errors.length ? 'block' : 'none';

			if (errors.length) {
				errors.forEach((error) => {
					const el = createErrorMessageElement(error);
					errorContainerEl?.appendChild(el);
				});
			}

			if (!uploadPromiseCbs.length) {
				if (!errorContainerEl.innerHTML) {
					announceEl.innerHTML = 'Uploading complete';
				} else {
					announceEl.innerHTML = 'Uploading complete. Some errors occured: ' + errorContainerEl.innerHTML;
				}
				announceEl.focus();
			}
		});

		Array.from(containerEl.querySelectorAll('.file_upload-remove')).forEach((removeEl) =>
			removeEl.addEventListener('click', (e) => {
				removeUploadedFile(e.target.dataset.id, containerEl, errorContainerEl, valueEl);

				inputEl.disabled = false;
				inputEl.parentElement.classList.remove('disabled');
			}),
		);
	});
}`;

const uploadFile = `var uploadFile = function(file, containerEl, errorContainerEl, valueEl){
	const fileWrapperEl = Array.from(containerEl.querySelectorAll('.file_upload-file-wrapper')).find((el) => !el.hasAttribute('data-id'));

    const includeMetadata = containerEl.getAttribute('data-use-file-metadata');

	fileWrapperEl.querySelector('.file_upload-file-name').textContent = file.name;
	fileWrapperEl.querySelector('.file_upload-file-size').textContent = bytesToPrettySize(file.size);
	fileWrapperEl.setAttribute('data-id', encodeURIComponent(file.name));
	fileWrapperEl.classList.add('loading');
	fileWrapperEl.style.display = 'block';

	const fileVaultUrl = '###__FILE_UPLOAD_URL__###';
	const formData = new FormData();
	formData.append('file', file);

	return () => {
		let success;

		return fetch(fileVaultUrl, {
			method: 'POST',
			body: formData,
		})
			.then((r) => {
				success = r.ok;
				return r.json();
			})
			.then((res) => {
				if (!success) throw res;

				const url = res.url;
				const value = JSON.parse(valueEl.value || '[]');

                if (includeMetadata) {
                    valueEl.value = JSON.stringify([...value, { name: file.name, url, extension: file.name.split('.').pop() }]);
                } else {
                    valueEl.value = JSON.stringify([...value, url]); 
                }

				fileWrapperEl.setAttribute('data-id', encodeURI(url));
				fileWrapperEl.querySelector('.file_upload-remove')?.setAttribute('data-id', encodeURI(url));
				fileWrapperEl.querySelector('.file_upload-remove')?.setAttribute('aria-label', 'Remove file: ' + file.name);
				fileWrapperEl.classList.remove('loading');
			})
			.catch((err) => {
				removeUploadedFile(encodeURIComponent(file.name), containerEl, undefined, valueEl);

				const message = file.name + ': ' + validationMessages.uploadFileGenericError;
				errorContainerEl.style.display = 'block';
				errorContainerEl.appendChild(createErrorMessageElement(message));
			});
	};
}`;

const removeUploadedFile = `var removeUploadedFile = function(fileId, containerEl, errorContainerEl, valueEl){
	if (errorContainerEl) {
		errorContainerEl.innerHTML = '';
		errorContainerEl.style.display = 'none';
	}

    const includeMetadata = containerEl.getAttribute('data-use-file-metadata');
	const value = JSON.parse(valueEl.value || '[]');
    let newValue;

    if (includeMetadata) {
        newValue = value.filter((fileInfo) => fileInfo.url !== fileId && fileInfo.url !== decodeURI(fileId));
    } else {
        newValue = value.filter((id) => id !== fileId && id !== decodeURI(fileId));
    }

	valueEl.value = newValue.length ? JSON.stringify(newValue) : '';

	const fileWrapperEl = containerEl.querySelector('.file_upload-file-wrapper[data-id="' + fileId + '"]');
	const announceEl = containerEl.querySelector('.a11y-announce');
	const filename = fileWrapperEl.querySelector('.file_upload-file-name').textContent;
	fileWrapperEl.style.display = 'none';
	fileWrapperEl.removeAttribute('data-id');
	fileWrapperEl.querySelector('.file_upload-remove')?.removeAttribute('data-id');
	fileWrapperEl.querySelector('.file_upload-remove')?.removeAttribute('aria-label');
	announceEl.innerHTML = 'Removed file: ' + filename;
	announceEl.focus();
}`;

const bytesToPrettySize = `var bytesToPrettySize = function(bytes){
	const sizes = { Bytes: 1, KB: 1024, MB: 1024 * 1024 };
	const unit =
		Object.keys(sizes)
			.reverse()
			.find((unit) => bytes >= sizes[unit]) || 'Bytes';

	const value = bytes / sizes[unit];
	return parseFloat(value.toFixed(2)) + ' ' + unit;
}`;

const cleanupFormFileUploadInputs = `var cleanupFormFileUploadInputs = function(){
	Array.from(form.querySelectorAll('.component.file_upload-component')).forEach((containerEl) => {
		const inputEl = containerEl.querySelector('.file_upload-input');
		const valueEl = inputEl.nextElementSibling;
		const errorContainerEl = containerEl.querySelector('.file_upload-error-container');

		inputEl.disabled = false;
		inputEl.parentElement.classList.remove('disabled');

		errorContainerEl.innerHTML = '';
		errorContainerEl.style.display = 'none';

		Array.from(containerEl.querySelectorAll('.file_upload-file-wrapper[data-id]')).forEach((fileWrapperEl) =>
			removeUploadedFile(fileWrapperEl.dataset.id, containerEl, errorContainerEl, valueEl),
		);
	});
}`;

const onInputCallbacks = `var onInputCallbacks = function(){
    var inputWrappers = form.querySelectorAll('div[class*=form-input-wrapper]');
    if (inputWrappers && inputWrappers.length){
        for (var i = 0; i < inputWrappers.length; i++){
            var inputElement = inputWrappers[i].querySelectorAll(".form-input-element");
            if (inputElement && inputElement.length){

                var setCounter = function(input, value) {
                    if (input.getAttribute('data-type') === 'radio') {
                        input.closest('[role="radiogroup"]').removeAttribute('aria-invalid');
                    } else if (input.getAttribute('data-type') === 'checkbox' && input.getAttribute('data-is-checkbox-group') === 'true') {
                        input.closest('[role="group"]').removeAttribute('aria-invalid');
                    } else {
                        input.removeAttribute('aria-invalid');
                    }

                    var parentNode = form.querySelector('[id="'+input.getAttribute('data-id')+'"]');

                    if (parentNode) {
                        parentNode.className = parentNode.className.replace(' form-input-error-field', '') + '';
                        var errorElement = parentNode.querySelectorAll('.form-input-error-message');
                        if (errorElement && errorElement.length){
                            parentNode.removeChild(errorElement[0]);
                        }
                        
                        var counterElement = parentNode.getElementsByClassName('limit-counter');
                        if (counterElement[0]){
                            counterElement[0].innerText = value.length + '/' + input.maxLength;
                        }
                    }
                }

                inputElement[0].oninput = function(e) {
                    setCounter(this, e.target.value);
                    runLogics(this);
                    triggerInteraction();
                };

                inputElement[0].onfocus = function() {
                    triggerInteraction();
                }
                
                runLogics(inputElement[0]);

                setCounter(inputElement[0], inputElement[0].value);
            }
        }
    }
}`;

const clearAllErrorMessages = `var clearAllErrorMessages = function(){
    var errorsLiveRegion = form.querySelector('[id="errors-live-region"]');
    errorsLiveRegion.innerHTML = '';

    var errorElements = [].concat(Array.from(form.querySelectorAll(".form-input-error-message")));
    for (var errorCount = 0; errorCount < errorElements.length; errorCount++){
        if (errorElements[errorCount]) {
            const parentNode = errorElements[errorCount].parentNode;
            errorElements[errorCount].parentNode.removeChild(errorElements[errorCount]);

            if (parentNode.classList.contains('component-error-container') && !parentNode.children.length) {
              parentNode.style.display = "none";
            }
        }
    }

    var inputErrors = form.querySelectorAll('div[class*=form-input-error-field]');
    for (var i = 0; i < inputErrors.length; i++){
        inputErrors[i].className = inputErrors[i].className.replace(' form-input-error-field', '') + '';
    }

    var invalidElements = form.querySelectorAll('[aria-invalid="true"]');
    for (var i = 0; i < invalidElements.length; i++) {
        invalidElements[i].removeAttribute('aria-invalid');
    }

    var recaptchaErrors = [].concat(Array.from(form.querySelectorAll('div[class*=form-recaptcha-error-message]')));
    for (var i = 0; i < recaptchaErrors.length; i++){
        if (recaptchaErrors[i]){
            recaptchaErrors[i].parentNode.removeChild(recaptchaErrors[i]);
        }
    }

    var successPopup = form.parentNode.querySelector('.success-popup');
    if (successPopup) {
        successPopup.style.display = 'none';
    }

    var failPopup = form.parentNode.querySelector('.fail-popup');
    if (failPopup) {
        failPopup.style.display = 'none';
    }
}`;

const formCloseEvent = `var formCloseEvent = function(){
    var eventName = 'moosend-form-close-event-' + '__ENTITY_ID__';
    var event = new CustomEvent(eventName, {});
    document.dispatchEvent(event);
}`;

const createSearchObject = `var createSearchObject = function() {
    var pairs = window.location.search.substring(1).split("&"),
        obj = {},
        pair;
  
    for (var i = 0; i < pairs.length; i++) {
        if ( pairs[i] === "" ) continue;
        pair = pairs[i].split("=");
        obj[ decodeURIComponent( pair[0].toLowerCase() ) ] = decodeURIComponent( pair[1] );
    }
  
    return obj;
}`;

const prefillFormFields = `var prefillFormFields = function(){
    var urlParams = createSearchObject();

    var inputs = Array.from(form.getElementsByClassName('params-${FieldComponentTypes.text_input}'))
    .concat(Array.from(form.getElementsByClassName('params-${FieldComponentTypes.date_input}')))
    .concat(Array.from(form.getElementsByClassName('params-${FieldComponentTypes.phone_input}')))
    .concat(Array.from(form.getElementsByClassName('params-${FieldComponentTypes.email_input}')))
    .concat(Array.from(form.getElementsByClassName('params-${FieldComponentTypes.number_input}')))
    .concat(Array.from(form.getElementsByClassName('params-${FieldComponentTypes.longtext_input}')));

    var radios = Array.from(form.getElementsByClassName('params-${FieldComponentTypes.radio}'));

    var selects = Array.from(form.getElementsByClassName('params-${FieldComponentTypes.singleselect_input}'));

    var multiSelects = Array.from(form.getElementsByClassName('params-${FieldComponentTypes.multiselect_input}'));

    var checkboxes = Array.from(form.getElementsByClassName('params-${FieldComponentTypes.checkbox}'));

    for (var i = 0; i < inputs.length; i++){
        if (inputs[i]){
            var prefillValue = inputs[i].getAttribute('data-prefill');
            var inputName = inputs[i].name;
            if (urlParams[inputName]){
                inputs[i].value = urlParams[inputName];
            }
            if (prefillValue && prefillValue.length > 0) {
                inputs[i].value = prefillValue;
            }
        }
    }

    for (var i = 0; i < radios.length; i++){
        if (radios[i]){
            var inputName = radios[i].name;
            if (urlParams[inputName] && urlParams[inputName] === radios[i].value){
                radios[i].checked = true;
            }
        }
    }

    for (var i = 0; i < selects.length; i++){
        if (selects[i]){
            var inputName = selects[i].name;
            if (urlParams[inputName]){
                selects[i].setAttribute('data-value', urlParams[inputName]);
            }
        }
    }

    for (var i = 0; i < multiSelects.length; i++){
        if (multiSelects[i]){
            var inputName = multiSelects[i].name;
            if (urlParams[inputName]){
                multiSelects[i].setAttribute('data-values', JSON.stringify(urlParams[inputName].split(',')));
            }
        }
    }

    for (var i = 0; i < checkboxes.length; i++){
        if (checkboxes[i]){
            var inputName = checkboxes[i].name;
            if (checkboxes[i].getAttribute('data-is-checkbox-group')){
                if (urlParams[inputName]){
                    const values = urlParams[inputName].split(',');
                    if (values.length && values.includes(checkboxes[i].value)){
                        checkboxes[i].checked = true;
                        checkboxes[i].nextSibling.style.display = 'block';
                    }
                }
            } else {
                if (urlParams[inputName] === 'true'){
                    checkboxes[i].checked = true;
                    checkboxes[i].nextSibling.style.display = 'block';
                } 
            }
        }
    }
}`;

const restrictNumberFields = `var restrictNumberFields = function(){
    var inputs = form.querySelectorAll('input[type="number"]');
    for (var i = 0; i < inputs.length; i++){
        if (inputs[i]){

            inputs[i].onkeypress = function(e){
                if (e.keyCode === 46 && e.target.value.includes('.')){
                    e.preventDefault();
                }
                var e = e || window.event;
                var charCode = (typeof e.which == "undefined") ? e.keyCode : e.which;
                var charStr = String.fromCharCode(charCode);

                var rgx = /^[0-9.-]+$/;
                if (!rgx.test(charStr)){
                    e.preventDefault();
                }
            }
            
            inputs[i].onpaste = function(e){
                var clipData = e.clipboardData;
                var content = clipData.getData('Text');
                var inputValue = content.replace(/e/g, '').replace(/,/g, '.');
                e.target.value =  e.target.value + inputValue;
                return false;
            }

        }
    }
}`;

const checkAndLoadDeps = `var checkAndLoadDeps = function() {
    var scripts = [
        {
            src: 'https://www.google.com/recaptcha/api.js',
            async: true,
            defer: true,
            id: undefined
        },
        {
            src: 'https://cdnjs.cloudflare.com/polyfill/v3/polyfill.min.js?version=4.8.0&features=Array.prototype.includes%2Cfetch%2CObject.assign%2CString.prototype.includes',
            async: true,
            defer: false,
            id: undefined
        },
        {
            src: 'https://cdn.jsdelivr.net/npm/intl-tel-input@21.0.1/build/js/intlTelInput.min.js',
            async: true,
            defer: false,
            id: 'tel-script'
        }
    ]

    var links = [
        {
            href: 'https://cdn.jsdelivr.net/npm/intl-tel-input@21.0.1/build/css/intlTelInput.css',
            rel: 'stylesheet'
        },
        {
            href: 'https://cdn.jsdelivr.net/npm/@mdi/font@7.2.96/css/materialdesignicons.min.css',
            rel: 'stylesheet'
        }
    ]

    for (var i = 0; i < scripts.length; i++){
        var foundScripts = document.querySelectorAll('script[src="' + scripts[i].src + '"]');
        if (!foundScripts.length){
            var script = document.createElement('script');
            scripts[i].defer && script.setAttribute('defer', scripts[i].defer);
            scripts[i].async && script.setAttribute('async', scripts[i].async);
            scripts[i].src && script.setAttribute('src', scripts[i].src);
            scripts[i].id && script.setAttribute('id', scripts[i].id);
            document.head.append(script);
        }
    }

    for (var i = 0; i < links.length; i++){
        var foundLinks = document.querySelectorAll('link[href="' + links[i].href + '"]');
        if (!foundLinks.length){
            var link = document.createElement('link');
            links[i].href && link.setAttribute('href', links[i].href);
            links[i].rel && link.setAttribute('rel', links[i].rel);
            document.head.append(link);
        }
    }
};
`;

const clearFormFields = `var clearFormFields = function() {
    var inputs = Array.from(form.getElementsByTagName('input')).concat(Array.from(form.getElementsByTagName('textarea')));
    var selectIndicators = Array.from(form.getElementsByClassName('selected-indicator'));
    var checkboxMarkers = Array.from(form.getElementsByClassName('checkbox-check-marker'));
    var elementsWithLimits = form.querySelectorAll('[data-selected-count]');

    var formId = form.getAttribute('data-formid');

    for (var i = 0; i < inputs.length; i++){
        if (inputs[i].name !== 'signature_' + formId){
            if (inputs[i].type === 'radio' || inputs[i].type === 'checkbox'){
                inputs[i].checked = false;
            } else {
                inputs[i].value = '';
                if (inputs[i].getAttribute('data-values')){
                    inputs[i].setAttribute('data-values', '[]');
                }
                if (inputs[i].getAttribute('data-value')){
                    inputs[i].setAttribute('data-value', '');
                }
                if (inputs[i].getAttribute('data-labels')){
                    inputs[i].setAttribute('data-labels', '');
                }
            }
        }
    }

    for (var i = 0; i < elementsWithLimits.length; i++){
        elementsWithLimits[i].removeAttribute('data-selected-count');
    }

    for (var i = 0; i < selectIndicators.length; i++){
        selectIndicators[i].classList.remove('mdi-check');
    }

    for (var i = 0; i < checkboxMarkers.length; i++){
        checkboxMarkers[i].style.display = 'none';
    }

    cleanupFormFileUploadInputs();
};
`;

const isIOS = `var isIOS = function() {
    return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
    ].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}`;

const padTo2Digits = `var padTo2Digits = function(num) {
    return num.toString().padStart(2, '0');
}`;

const renderRecaptcha = `var renderRecaptcha = function(){
    var recaptchaElement = form.querySelector('#recaptcha' + form.getAttribute('data-formid'))
    if (recaptchaElement){
        var invisibleRecaptcha = recaptchaElement.getAttribute('data-size') === 'invisible'
        var recaptchaKey = invisibleRecaptcha ? '###__INVISIBLE_RECAPTCHA_KEY__###' : '###__RECAPTCHA_KEY__###';
        try {
            if (!grecaptcha && grecaptchaTries < 10) {
                setTimeout(function () {
                    grecaptchaTries = grecaptchaTries + 1;
                    renderRecaptcha();
                }, 500);
                return;
            }
            recaptchaInstance = grecaptcha.render(recaptchaElement.id, {
                'sitekey': recaptchaKey,
                'theme': recaptchaElement.getAttribute('data-theme')
            });
            if (recaptchaElement.getAttribute('data-size') === 'invisible') {
                grecaptcha.execute(recaptchaInstance)
            }
        } catch(e){
            setTimeout(function () {
                grecaptchaTries = grecaptchaTries + 1;
                renderRecaptcha();
            }, 1000);
        }
    }
}`;

const preparePopupMessageClose = `var preparePopupMessageClose = function(){
    var popupMessages = document.querySelectorAll('.message-popup-close');
    for (var i = 0; i < popupMessages.length; i++){
        popupMessages[i].onclick = function(){
            this.parentNode.style.display = 'none';
        }
    }
}`;

const triggerSuccessAction = `var triggerSuccessAction = function(){
    clearFormFields();
    onInputCallbacks();

    var successAction = form.getAttribute('data-success-action');
    var successPopup = form.parentNode.querySelector('.success-popup');

    if (successAction && successAction === 'redirectUrl') {
        var redirectUrlAttribute = form.getAttribute('data-success-redirect-url');
        var redirectUrl = redirectUrlAttribute.includes('http') ? redirectUrlAttribute : 'http://' + redirectUrlAttribute;
        window.top.location.href = redirectUrl;
    }
    successPopup.style.display = 'block';
    // For no success action or empty success message, show a default message for screen readers only
    if (!successPopup.innerHTML) {
        successPopup.innerHTML = '<div class="a11y-visible">Form has been submitted successfully.</div>';
    }
    
    prefillFormFields();
}`;

const triggerFailAction = `var triggerFailAction = function(){
    var failAction = form.getAttribute('data-fail-action');

    if (failAction) {
        if (failAction === 'message'){
            var failPopup = form.parentNode.querySelector('.fail-popup');
            failPopup.style.display = 'block';
            // setTimeout(() => {
            //     failPopup.style.display = 'none';
            // }, 5000);
        }
    }
}`;

const evaluateCriterion = `var evaluateCriterion = function(inputValue, fieldType, criterion) {
	switch (criterion.comparer) {
		case 'contains':
			return inputValue.toLowerCase().includes(criterion.value.toLowerCase());
		case 'does_not_contain':
			return !inputValue.toLowerCase().includes(criterion.value.toLowerCase());
		case 'starts_with':
			return inputValue.toLowerCase().startsWith(criterion.value.toLowerCase());
		case 'ends_with':
			return inputValue.toLowerCase().endsWith(criterion.value.toLowerCase());
		case 'is_equal_to':
		case 'is':
			if (Array.isArray(inputValue)) {
				return inputValue.join('|') === criterion.value.join('|');
			}
			
			return inputValue.toLowerCase() === criterion.value.toLowerCase();
		case 'is_not_equal_to':
		case 'is_not':
			if (Array.isArray(inputValue)) {
				return inputValue.join('|') !== criterion.value.join('|');
			}
			
			

			return inputValue.toLowerCase() !== criterion.value.toLowerCase();
		case 'is_empty':
			if (Array.isArray(inputValue)) {
				return inputValue.length === 0;
			}

			return inputValue === '';
		case 'is_not_empty':
			if (Array.isArray(inputValue)) {
				return inputValue.length > 0;
			}

			return inputValue !== '';
		case 'is_checked':
			return inputValue === true;
		case 'is_not_checked':
			return inputValue === false;
		case 'is_greater_than':
			if (inputValue) {
				const inputValueDec = parseFloat(inputValue, 10);
				const criterionValueDec = parseFloat(criterion.value, 10);

				return inputValueDec > criterionValueDec;
			}

			return false;
		case 'is_smaller_than':
			if (inputValue) {
				const inputValueDec = parseFloat(inputValue, 10);
				const criterionValueDec = parseFloat(criterion.value, 10);

				return inputValueDec < criterionValueDec;
			}

			return false;
		case 'is_before':
			if (inputValue) {
				const inputValueDt = new Date(inputValue);
				const criterionValueDt = new Date(criterion.value);

				return inputValueDt < criterionValueDt;
			}

			return false;
		case 'is_after':
			if (inputValue) {
				const inputValueDt = new Date(inputValue);
				const criterionValueDt = new Date(criterion.value);

				return inputValueDt > criterionValueDt;
			}

			return false;
		case 'is_between':
			if (inputValue) {
				if (fieldType === 'number_input') {
					const inputValueDec = parseFloat(inputValue, 10);
					const criterionStartValueDec = parseFloat(criterion.value.start, 10);
					const criterionEndValueDec = parseFloat(criterion.value.end, 10);

					return inputValueDec > criterionStartValueDec && inputValueDec < criterionEndValueDec;
				} else if (fieldType === 'date_input') {
					const inputValueDt = new Date(inputValue);
					const criterionStartValueDt = new Date(criterion.value.start);
					const criterionEndValueDt = new Date(criterion.value.end);

					return inputValueDt > criterionStartValueDt && inputValueDt < criterionEndValueDt;
				}
			}

			return false;
		default:
			return false;
	}
}`;

const evaluateCondition = `var evaluateCondition = function(field, criteria) {
  var inputValue;
  
	if (field.type === 'checkbox_group') {
		const elements = form.querySelectorAll('[name="' + field.name + '"][type="checkbox"]:checked');

		inputValue = [];

		for (var i = 0; i < elements.length; i++) {
			inputValue.push(elements[i].value);
		}

		inputValue.sort();
	} else if (field.type === 'radio') {
		const element = form.querySelector('[name="' + field.name + '"][type="radio"]:checked');

		inputValue = element ? element.value : '';
	} else if (field.type === 'multiselect_input') {
		const element = form.querySelector('[name="' + field.name + '"]');

		inputValue = JSON.parse(element.getAttribute('data-values')).sort();
	} else if (field.type === 'singleselect_input') {
		const element = form.querySelector('[name="' + field.name + '"]');

		inputValue = element.getAttribute('data-value') ?? '';
	} else if (field.type === 'checkbox' || field.type === 'gdpr') {
		const element = form.querySelector('[name="' + field.name + '"]');

		inputValue = element.checked;
	} else {
		const element = form.querySelector('[name="' + field.name + '"]');

		inputValue = element.value;
	}

	for (var i = 0; i < criteria.length; i++) {
		if (evaluateCriterion(inputValue, field.type, criteria[i])) {
			return true;
		}
	}

	return false;
}`;

const and = `var and = function(operands) {
	for (var i = 0; i < operands.length; i++) {
		const operand = operands[i];
		var result;

		if (operand.type === 'expression') {
			result = evaluateExpression(operand.expression);
		} else {
			result = evaluateCondition(operand.field, operand.criteria);
		}

		if (result === false) {
			return false;
		}
	}

	return true;
}`;

const or = `var or = function(operands) {
	for (var i = 0; i < operands.length; i++) {
		const operand = operands[i];
		var result;

		if (operand.type === 'and') {
			result = and(operand.operands);
		} else if (operand.type === 'expression') {
			result = evaluateExpression(operand.expression);
		} else {
			result = evaluateCondition(operand.field, operand.criteria);
		}

		if (result === true) {
			return true;
		}
	}

	return false;
}`;

const evaluateExpression = `var evaluateExpression = function(expression) {
	if (expression.type === 'or') {
		return or(expression.operands);
	}

	if (expression.type === 'and') {
		return and(expression.operands);
	}

	if (expression.type === 'expression') {
		return evaluateExpression(expression.expression);
	}

	return evaluateCondition(expression.field, expression.criteria);
}`;

const setFieldVisibility = `var setFieldVisibility = function(element, wrapperElement, displayValue) {
	if ( displayValue === 'none' && element.getAttribute('data-required') === 'true') {
		element.setAttribute('data-is-initially-required', 'true');
		element.setAttribute('data-required', 'false');
		element.removeAttribute('required');
	} else if (displayValue === 'block' && element.getAttribute('data-is-initially-required') === 'true') {
		element.setAttribute('data-required', 'true');
		element.setAttribute('required', '');
		element.removeAttribute('data-is-initially-required');
	}

	wrapperElement.style.display = displayValue;
}`;

const setFieldOptionality = `var setFieldOptionality = function(element, wrapperElement, isOptional) {
	element.removeAttribute('data-is-initially-required');

	if (isOptional) {
		element.setAttribute('data-required', 'false');
		element.removeAttribute('required');
		wrapperElement.classList.remove('required-component');
	} else {
		element.setAttribute('data-required', 'true');
		element.setAttribute('required', '');
		wrapperElement.classList.add('required-component');
	}
}`;

const runAction = `var runAction = function(action, result) {
    var element;
    var wrapperElement;

    if (action.field.type === 'radio') {
        element = form.querySelector('[name="' + action.field.name + '"].radio-group-input');
    } else if (action.field.type === 'checkbox_group') {
        element = form.querySelector('[name="' + action.field.name + '"].checkbox-group-input');
    } else {
        element = form.querySelector('[name="' + action.field.name + '"]');
    }

    wrapperElement = form.querySelector('[id="' + element.getAttribute('data-id') + '"]');

    if (action.type === 'hide_field') {
        const displayValue = result ? 'none' : 'block';

        setFieldVisibility(element, wrapperElement, displayValue);
    } else if (action.type === 'show_field') {
        const displayValue = result ? 'block' : 'none';

        setFieldVisibility(element, wrapperElement, displayValue);
    } else if (action.type === 'mandatory_field') {
        const isOptional = result ? false : true;

        setFieldOptionality(element, wrapperElement, isOptional);
    } else if (action.type === 'optional_field') {
        const isOptional = result ? true : false;

        setFieldOptionality(element, wrapperElement, isOptional);
    }
}`;

const runLogics = `var runLogics = function(input) {
	if (!fieldLogics[input.name]) {
		return;
	}

	for (var i = 0; i < fieldLogics[input.name].length; i++) {
		var logicIdx = fieldLogics[input.name][i];
		var logic = logics[logicIdx];

		logic.result = evaluateExpression(logic.expression);
	}

	for (var i = 0; i < logics.length; i++) {
		var logic = logics[i];

		for (var j = 0; j < logic.actions.length; j++) {
			runAction(logic.actions[j], logic.result);
		}
	}
}`;

const triggerInteraction = `var triggerInteraction = function(){
    if (!formInteracted){
        const event = new CustomEvent("form:engage", {bubbles: true, detail: {name: 'INTERACTED', formId: form.getAttribute('data-formid')}} );
        form.dispatchEvent(event);
        formInteracted = true;
    }
}`;

const triggerView = `var triggerView = function(){
    if (!formViewed){
        const event = new CustomEvent("form:engage", {bubbles: true, detail: {name: 'VIEWED', formId: form.getAttribute('data-formid')}} );
        form.dispatchEvent(event);
        formViewed = true;
    }
}`;

const triggerSubmit = `var triggerSubmit = function(){
    const event = new CustomEvent("form:engage", {bubbles: true, detail: {name: 'SUBMITTED', formId: form.getAttribute('data-formid')}} );
    form.dispatchEvent(event);
}`;

export const designerScript = (entityId, isPreview, additionalData) => {
	return `
    (function() {
        var form = document.currentScript.parentNode.querySelector('[data-formid="' + '${entityId}' + '"]');
        var currentPage = 0;
        var recaptchaInstance;
        var grecaptchaTries = 0;
        var formInteracted = false;
        var formViewed = false;
        var logics = ${JSON.stringify(additionalData.logics)};
        var fieldLogics = ${JSON.stringify(additionalData.fieldLogics)};
        var validationMessages = ${JSON.stringify(additionalData.validation_messages)};
        var summaryPage = form.querySelector('[id="summary-page"]');

        ${triggerInteraction}

        ${triggerView}

        ${triggerSubmit}

        ${evaluateCriterion}
        
        ${evaluateCondition}
        
        ${and}
        
        ${or}
        
        ${evaluateExpression}

        ${setFieldVisibility}

        ${setFieldOptionality}

        ${runAction}

        ${runLogics}

        ${showNewPage}

        ${padTo2Digits}

        ${isIOS}
        
        ${postData}
    
        ${getFormData}
    
        ${correctObjectStructure}
    
        ${createErrorMessageElement}

        ${validateFormInputs}
    
        ${getCollectionValues}

        ${transformInputValue}
    
        ${buttonCallback}
    
        ${addSubmitEventToButton}

        ${addNextEventToButton}

        ${addBackEventToButton}

        ${addGoToPageEventToButton}

        ${addSummaryPageCollapseEventToButton}

        ${setSummaryPageFieldValues}

        ${initSummaryPage}
    
        ${readyFormSubmit}
    
        ${checkboxOnClickEvents}

        ${onInputCallbacks}
    
        ${clearAllErrorMessages}
    
        ${prepareFormSelects}

        ${prepareFormMultiSelects}

        ${prepareFormPhoneInputs}

        ${promiseAllSettledSequencial}

        ${uploadFile}

        ${removeUploadedFile}

        ${bytesToPrettySize}

        ${cleanupFormFileUploadInputs}
        
        ${prepareFormFileUploadInputs}
    
        ${formCloseEvent}

        ${createSearchObject}

        ${prefillFormFields}

        ${restrictNumberFields}

        ${checkAndLoadDeps}

        ${clearFormFields}

        ${renderRecaptcha}

        ${preparePopupMessageClose}

        ${triggerSuccessAction}

        ${triggerFailAction}
        
        ${messageListener}

        readyFormSubmit("${entityId}", ${isPreview});
    })();
`;
};
